.page-content .wp-smiley,
.entry-content .wp-smiley,
.comment-content .wp-smiley {
	border: none;
	margin-bottom: 0;
	margin-top: 0;
	padding: 0;
}

embed,
iframe,
object {
	max-width: 100%;
}

.custom-logo-link {
	display: inline-block;
}

.avatar {
	border-radius: 100%;
	display: block;
	height: calc(2.25 * #{$size__spacing-unit});
	min-height: inherit;
	width: calc(2.25 * #{$size__spacing-unit});
}

svg {
	transition: fill $icon_transition ease-in-out;
	fill: currentColor;
}

/*--------------------------------------------------------------
## Captions
--------------------------------------------------------------*/
@import "captions";

/*--------------------------------------------------------------
## Galleries
--------------------------------------------------------------*/
@import "galleries";
