@import '../../themes/variables';

.AuthorInfo {
  font-size: 1em;
  display: inline-block;

  img {
    float: left;
    margin: 0 8px 0 0;
  }

  &:after {
    display: block;
    content: "";
    clear: both;
  }

  &-name, &-date {
    white-space: nowrap;
  }

  &-name {
    display: inline-block;
    padding-top: 6px;
  }

  &-date {
    display: block;
    font-size: 0.8em;
    color: #95999c;
  }

  &.right-aligned {
    text-align: right;

    img {
      float: right;
      margin: 0 0 0 8px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .AuthorInfo {
  }
}