@import '../../themes/variables';

.PageSectionGroup {
  padding: 20px 0;

  h1 {
    margin: 2rem 0 0 0;
    text-shadow: 0 2px rgba(0,0,0,0.6);
  }

  &-title {
    text-transform: capitalize;
  }

  .hook-link {
    color: $secondary-font-color;
    text-decoration: none;

    &:visited {
      color: $secondary-font-color;
    }
  }

  &-hide-post-details .post-details {
    display: none;
  }

  &-hide-title {
    &-with-background {
      .PageSectionGroup-content-wrapper {
        margin-top: 80px;
      }
    }

    .PageSectionGroup-title {
      display: none;
    }
  }

  &-with-background {
    width: 100%;
    padding-bottom: 48px;
    margin: 0;
    position: relative;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.6);

    .page-container {
      color: $secondary-font-color;

      &-content-group {
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.6);
        background: rgba(255, 255, 255, 0.7);
      }
    }

    &.has-more-neighbours {
      margin-bottom: -32px;
    }
  }

  &-background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: no-repeat center top;
    background-size: 100% auto;

    span {
      position: absolute;
      right: 10px;
      bottom: 0;
      font-weight: bold;
    }
  }
}

@media only screen and (min-width: 480px) {
  .PageSectionGroup {
    padding: 20px 0;

    &-with-background {
      &:not(.has-more-neighbours) {
        min-height: 720px;
      }
    }
  }
}

@media only screen and (max-width: 1320px) {
  .PageSectionGroup {
    h1 {
      margin: 0;
      padding: 24px 6px 0 6px;
    }

    &-background {
      background-size: cover;
    }
  }
}

@media only screen and (max-width: 480px) {
  .PageSectionGroup {
    &-with-background {
      padding: 0;

      .PageSectionGroup-background {
        opacity: 0.4;
      }
    }

    h1 {
      font-size: 1.6em;
    }
  }
}
