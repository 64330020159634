// Responsive widths.

$size__spacing-unit: 1rem;
$size__site-main: 100%;
$size__site-sidebar: 25%;
$size__site-margins: calc(10% + 60px);
$size__site-tablet-content: calc(8 * (100vw / 12) - 28px);
$size__site-desktop-content: calc(6 * (100vw / 12) - 28px);

// Responsive widths.

$mobile_width: 600px;
$tablet_width: 768px;
$desktop_width: 1168px;
$wide_width: 1379px;

