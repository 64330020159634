
html {
	font-size: $font__size_base;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: $color__text-main;
	font-family: $font__body;
	font-weight: 400;
	font-size: 1em;
	line-height: $font__line-height-body;
	margin: 0;
	text-rendering: optimizeLegibility;
}

button,
input,
select,
optgroup,
textarea {
	color: $color__text-main;
	font-family: $font__body;
	font-weight: 400;
	line-height: $font__line-height-body;
	text-rendering: optimizeLegibility;
}

@import "headings";

@import "copy";

@include non-latin-fonts();
