@import '../../themes/variables';

.PageFooter {
  text-align: center;
  background-color: $header-bg-color;
  color: $secondary-font-color;
  border-top: 4px solid $main-theme-color;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.4);
  padding-top: 20px;

  &-group {
    &-container {
      display: inline-block;
      float: left;
      margin: 0 0 12px 0;
      width: 33%;
      text-align: center;

      a.social {
        color: $secondary-font-color;
        text-decoration: none;

        &:hover {
          color: $main-theme-color;
        }
      }
    }

    &-title {
      margin: 0;
      padding: 0 0 4px 0;
      font-size: 1.2em;
      font-weight: normal;
      font-family: 'Cinzel', serif;
    }
  }

  &-menu {
    &-container {
      position: relative;
      height: 100%;
      margin: 0;
      padding: 0;
      font-size: 1.0em;
      line-height: 1.5em;
      list-style-type: none;
    }

    &-item {
      position: relative;
      padding: 0;
      margin: 0;
      display: inline-block;
      text-transform: capitalize;

      &:not(:last-child):after {
        display: inline-block;
        content: "•";
        padding: 0 6px;
      }
    }
  }

  .credit {
    clear: both;
    margin: 0;
    padding: 8px;
  }
}

@media only screen and (min-width: 481px) {
  .PageFooter {
    .credit {
      span:not(:last-child):after {
        display: inline-block;
        content: "•";
        padding: 0 6px;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .PageFooter {
    padding-top: 0;

    .credit span {
      display: block;
    }

    &-group {
      &-container {
        display: none;
      }
    }
  }
}