/** === Footer menu === */

.footer-navigation {

	display: inline;

	& > div {
		display: inline;
	}

	.footer-menu {

		display: inline;
		padding-left: 0;

		li {
			display: inline;
			margin-right: 1rem;
		}
	}

}