.alignleft {
	/*rtl:ignore*/
	float: left;
	/*rtl:ignore*/
	margin-right: $size__spacing-unit;

	@include media(tablet) {
		/*rtl:ignore*/
		margin-right: calc(2 * #{$size__spacing-unit});
	}
}

.alignright {
	/*rtl:ignore*/
	float: right;
	/*rtl:ignore*/
	margin-left: $size__spacing-unit;

	@include media(tablet) {
		/*rtl:ignore*/
		margin-left: calc(2 * #{$size__spacing-unit});
	}
}

.aligncenter {
	clear: both;
	@include center-block;
}
