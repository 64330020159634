@import '../../themes/variables';

.SocialMedia {
  .social {
    padding: 0 6px;

    svg {
      font-size: 1.3em;
    }
  }

  .btn {
    padding: 0 6px;
  }

  .dropdown-toggle {
    display: inline-block;

    &::after {
      display: none;
    }
  }

  .btn-link {
    color: #616c75;
    font-size: 1.2em;
    text-decoration: none;
  }

  .facebook:hover {
    color: #4267B2;
  }

  .instagram:hover {
    color: #8134AF;
  }

  .youtube:hover {
    color: #FF0000;
  }

  .twitter:hover {
    color: #1DA1F2;
  }

  .goodreads:hover {
    color: #F4F1EA;
  }
}

@media only screen and (max-width: 767px) {
  .SocialMedia {
    .social {
      padding: 0 4px;
    }
  }
}