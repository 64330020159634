a {
	@include link-transition;
	color: $color__link;

	&:visited {
		color: $color__link-visited;
	}

	&:hover,
	&:active {
		color: $color__link-hover;
		outline: 0;
		text-decoration: none;
	}

	&:focus {
		outline: none;
		text-decoration: underline;
	}
}
