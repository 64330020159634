input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
	-webkit-backface-visibility: hidden;
	background: $color__background-input;
	border: solid 1px $color__border;
	box-sizing: border-box;
	outline: none;
	padding: #{.36 * $size__spacing-unit} #{.66 * $size__spacing-unit};
	-webkit-appearance: none;
	outline-offset: 0;
	border-radius: 0;

	&:focus {
		border-color: $color__link;
	}
}

input[type="search"] {
	&::-webkit-search-decoration {
		display: none;
	}
}

select {

}

textarea {
	box-sizing: border-box;
	display: block;
	width: 100%;
	max-width: 100%;
	resize: vertical;
}

form {

	p {
		margin: $size__spacing-unit 0;
	}

}