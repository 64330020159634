@import '../../themes/variables';

.PageSection {
  padding: 20px 0;

  .PageView {
    display: flex;
    align-items: stretch;

    .featured-image {
      flex: none;
      margin: 0;
    }
  }

  &-with-background {
    width: 100%;
    padding-bottom: 48px;
    margin: 0;
    position: relative;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.6);

    &.has-more-neighbours {
      margin-bottom: -32px;
    }

    .hook-link {
      color: $secondary-font-color;
      text-decoration: none;

      &:visited {
        color: $secondary-font-color;
      }
    }
  }

  &-background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: no-repeat center;
    background-size: cover;

    span {
      position: absolute;
      right: 10px;
      bottom: 0;
      font-weight: bold;
    }
  }

  &-body {
    margin: 6px 12px 20px 12px;
  }

  &:not(&-with-background) {
    .hook-link {
      color: #2e2e2e;
      text-decoration: none;

      &:visited {
        color: #2e2e2e;
      }
    }
  }

  &-footer {
    margin-top: 12px;

    .page-container-content-group {
      align-items: center;
    }

    .AmazonBadge {
      margin: 12px;
    }

    .actions {
      padding: 8px 2px;
      margin: 0;
      display: inherit;
    }
  }

  .AuthorInfo {
    position: absolute;
    top: 0;
    right: 0;
    margin: 12px 26px;
    //margin-bottom: 20px;
  }
}

@media only screen and (max-width: 1024px) {
  .PageSection {
    .AuthorInfo {
      position: relative;
      margin: -18px 0 12px 0;
    }
  }
}

@media only screen and (min-width: 720px) {
  .PageSection {
    padding: 20px 0;
  }
}

@media only screen and (max-width: 480px) {
  .PageSection {
    .PageView {
      display: block;

      .featured-image {
        width: 100% !important;
        height: 100% !important;
        margin-bottom: 12px;
      }

      &-with-background {
        padding: 0;

        .PageSection-background {
          opacity: 0.4;
        }
      }
    }

    &-body {
      padding: 12px 0;
    }
  }
}
