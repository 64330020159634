@import "themes/variables";

.App {
  min-width: 300px;

  .loader-container {
    text-align: center;
    width: 200px;
    margin: 200px auto;
    padding: 12px 6px;
    background: rgba(255,255,255,0.8);
    border-radius: 5px;
  }

  &-content {
    .PageSection:first-child .page-section {
      &-with-background .page-section-background {
        box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.8);
      }
    }

    .PageSection:nth-of-type(even) {
      .page-container-content-group {
        direction: rtl;
        justify-content: flex-end;

        .featured-image {
          margin: 0 0 0 12px;
        }
      }
    }
  }

  .wp-block-embed-amazon-kindle {
    margin: 0;

    iframe {
      width:100%;

      body {
        background-color: transparent;
      }
    }
  }

  .book-preview {
    width: 100%;
    height: 600px;
  }
}
