@import '../../themes/variables';

.AmazonAction {
  display: inline-block;

  svg {
    font-size: 1.3em;
  }

  .dropdown {
    &-menu {
      box-shadow: 0 1px 8px rgba(0, 0, 0, 0.6);
      background: rgba(255, 255, 255, 0.9);
      padding: 6px;
      width: 280px;

      img {
        height: 15px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6);
      }
    }

    &-header {
      text-transform: capitalize;
      padding-left: 6px;
    }

    &-item {
      padding: 0 6px;
      display: inline-block;
      width: auto;
    }

    &-toggle::after {
      border: 0;
    }

    &-divider:last-child {
      display: none;
    }
  }
}
