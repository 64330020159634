@import '../../themes/variables';

.BookPageSection {
  &-actions-container {
    position: absolute;
    left: 6px;
    right: 6px;
    bottom: 30px;
  }

  &-actions {
    display: flex;
    justify-content: flex-end;

    &-section {
      padding: 8px 2px;

      &:not(:first-child) {
        text-align: center;
      }
    }

    .action-button {
      margin: 0 4px;

      .AmazonAction, .dropdown, .dropdown-toggle {
        width: 100%;
        height: 100%;
      }
    }
  }

  .SocialMedia {
    padding: 12px;
    a {
      color: $secondary-font-color;
      font-size: 1.2em;
      text-decoration: none;
    }
  }

  .CardView {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px -6px;

    .PageView {
      padding: 12px;
      flex-basis: 320px;
      flex-grow: 2;
      margin: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      hr {
        background-color: #a4adb5;
        height: 1px;
        margin: 0 20px;
      }

      h4 {
        margin-bottom: 2px;
      }
    }
  }
}

@media only screen and (min-width: 481px) {
  .BookPageSection {
    .PageSection {
      &-body {
        margin-bottom: 52px;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .BookPageSection {
    .PageSection {
      &-body {
        padding-bottom: 54px;
      }
    }

    &-actions-container {
      bottom: 52px;
    }

    &-actions {
      justify-content: space-between;

      .action-button {
        flex-grow: 1;
      }
    }
  }
}