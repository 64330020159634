@import '../../themes/variables';

.PageHeader {
  background-color: $header-bg-color;
  color: $secondary-font-color;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.8);
  border-bottom: 4px solid $main-theme-color;

  .PageContent {
    height: 116px;
  }

  &-title-container {
    display: inline-block;
    padding: 12px 16px;

    .PageHeader-title {
      padding: 0;
      margin: 0;
      font-size: 3.0em;
      font-family: 'Cinzel', serif;
      text-shadow: 0 2px rgba(0,0,0,0.6);

      :last-child {
        color: $main-theme-color;
      }

      &-tagline {
        padding: 0 15px 0 0;
        margin: 0;
        font-size: 1.6em;
        font-family: 'Lora', sans-serif;
        font-weight: normal;
        line-height: 0.3em;
        text-align: right;
        text-transform: lowercase;
      }
    }

    a {
      color: $secondary-font-color;
      text-decoration: none;

      &:visited {
        color: $secondary-font-color;
      }
    }

    .SocialMedia {
      margin: -12px 0 0 24px;
    }
  }

  &-menu {
    &-container {
      position: relative;
      float: right;
      height: 100%;
      margin: 0;
      padding: 0;
      font-size: 1.8em;
      list-style-type: none;
    }

    &-item {
      position: relative;
      display: inline-block;
      width: 120px;
      height: 100%;
      padding: 0;
      margin: 0;
      color: $secondary-font-color;
      text-transform: capitalize;

      &:hover, &.active {
        background-color: $main-theme-color;
        color: #ccc;
      }

      a {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;
        text-decoration: none;
        color: inherit;
        padding: 8px;
        font-family: 'Cinzel', serif;
        //text-transform: uppercase;
      }
    }

    &-toggler {
      display: none;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .PageHeader {
    height: auto;

    .PageContent {
      height: auto;
    }

    &-title-container {
      padding: 6px 16px;
      margin-bottom: 6px;

      .PageHeader-title {
        font-size: 2.0em;

        &-tagline {
          font-size: 1.2em;
          line-height: 0.5em;
        }
      }

      .SocialMedia {
        position: absolute;
        right: 64px;
        top: 18px;
        margin: 0;
      }
    }

    &-menu {
      &-toggler {
        display: block;
        float: right;
        background: url('./menu-icon.svg') no-repeat center;
        width: 32px;
        height: 32px;
        padding: 6px;
        margin: 12px 6px 0 6px;
      }

      &-container {
        &.open {
          display: block;
        }

        display: none;
        margin: 0 4px 4px 0;
        float: none;
        text-align: right;

        .PageHeader {
          &-menu-item {
            display: block;
            text-align: center;
            width: auto;

            &:hover {
              background-color: transparent;
            }

            a {
              position: relative;
              font-size: 0.8em;
              padding: 0;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 720px) {
  .PageHeader {
    &-title-container {
      .PageHeader-title {
        font-size: 2.0em;

        &-tagline {
          display: none;
        }
      }

      .SocialMedia {
        display: none;
      }

      .open {
        .SocialMedia {
          display: block;
          right: 0;
          top: 0;
          position: relative;
          text-align: center;
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .PageHeader {
    &-title-container {
      padding: 6px 16px;
      margin-bottom: 0;

      .PageHeader-title {
        font-size: 1.6em;
      }
    }

    &-menu {
      margin: 0;

      &-toggler {
        margin: 8px 6px 0 6px;
      }
    }
  }
}

@media only screen and (max-width: 360px) {
  .PageHeader {
    &-title-container {
      padding: 10px 8px;
    }
  }
}