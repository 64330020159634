@import '../../themes/variables';

.BookReview {
  text-align: center;

  .fa-star {
    color: #cc7a00;
  }

  &-quote {
    position: relative;
    text-align: center;

    p {
      font-size: 0.9em;
      margin: 6px 26px;
      display: inline-block;
      float: left;
    }

    &:before {
      position: absolute;
      left: 0;
      top: -12px;
      content: "“";
    }

    &:after {
      text-align: right;
      line-height: 0;
      display: block;
      content: "”";
      clear: both;
    }

    &:before, &:after {
      font-size: 4em;
      color: #95999c;
    }
  }

  &-rating {
    clear: both;
    display: block;
    margin: 8px 0;
  }

  &-author {
    display: block;
    text-align: right;
    font-size: 0.8em;
    color: #95999c;
    margin-right: 8px;
  }

  &-url {
    float: right;
    display: inline-block;
    font-size: 1.2em;
    padding: 0 6px;
    color: #616c75;

    &:visited {
      color: #616c75;
    }
  }
}

@media only screen and (max-width: 767px) {
  .BookReview {
  }
}