$midnight-blue: #040c0e;
$dark-slate-gray: #162C34;
$dark-gray: #a3a490;
$slate-gray: #687069;
$dark-slate-gray-2: #354245;

$header-bg-color: #22272c; //#1b262c;
$main-theme-color: #34526f;
$secondary-font-color: #616c75;

$footer-border-color: #18426b;

$body-color: #233f5a;

$light-font-color: #dcdcdc;

// #1b262c
// #0f4c75
// #3282b8
// #bbe1fa#627384