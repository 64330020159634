.button,
button:not(.btn-link),
input[type="button"],
input[type="reset"],
input[type="submit"] {
	@include button-transition;
	background: $color__background-button;
	border: none;
	border-radius: 5px;
	box-sizing: border-box;
	font-family: $font__heading;
	font-size: $font__size-sm;
	//font-weight: 700;
	line-height: $font__line-height-heading;
	outline: none;
	padding: ( $size__spacing-unit * .76 ) $size__spacing-unit;
	text-decoration: none;
	vertical-align: bottom;

	&:hover {
		background: $color__background-button-hover;
		cursor: pointer;
	}

	&:visited {
		color: $color__background-body;
		text-decoration: none;
	}

	&:focus {
		background: $color__background-button-hover;
	}

	svg, .svg-inline--fa {
		font-size: 1.2em;
		float: left;
		margin: 2px 6px 0 0;
	}
}

.btn-primary, .btn-primary:active {
	&:active, &.dropdown-toggle {
		background: $color__background-button;
	}
}

button, .btn {
	color: $light-font-color
}