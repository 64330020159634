@import "../../variables";

// Backgrounds
$color__background-body: #fff;
$color__background-input: #fff;
$color__background-screen: #f1f1f1;
$color__background-hr: #ccc;
$color__background-button: $main-theme-color;
$color__background-button-hover: darken( $main-theme-color, 10% );
$color__background-pre: #eee;
$color__background-ins: #fff9c0;
$color__background_selection: mix( $color__background-body, $color__background-button, 75% ); // lighten( salmon, 22.5% ); // lighten( #0999d4, 48% );

// Text
$color__text-main: #111;
$color__text-light: #767676;
$color__text-hover: lighten( #111, 22.5% );
$color__text-screen: #21759b;
$color__text-input: #666;
$color__text-input-focus: #111;

// Links
$color__link: $main-theme-color;
$color__link-visited: $main-theme-color;
$color__link-hover: darken( $color__link, 10% );

// Borders
$color__border: #ccc;
$color__border-link: $main-theme-color;
$color__border-link-hover: darken( $color__link, 10% );
$color__border-button: #ccc #ccc #bbb;
$color__border-button-hover: #ccc #bbb #aaa;
$color__border-button-focus: #aaa #bbb #bbb;
$color__border-input: $color__border;
$color__border-abbr: #666;
